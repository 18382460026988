import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Router } from '@angular/router';
import { JsonFormsModule } from '@jsonforms/angular';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { HttpContextConfig } from './shared/models/http-context-config.model';
import { AuthService } from './shared/services/auth.service';

registerLocaleData(en);

export const CONFIG = new HttpContextToken<HttpContextConfig>(() => new HttpContextConfig())

function initializeAppFactory(auth: AuthService, router: Router): () => Observable<any> {

  // Get logged user if exists
  return () =>
    auth.getUser(true).pipe(
      tap((response: any) => {
        if (response) auth.currentUser.next(response.data?.user);
      }),
      catchError((error: any) => {

        if (error.status == 401 && !window.location.pathname.includes('/auth/password-reset')) {
          router.navigate(['/auth/login']);
          localStorage.clear();
        }
        return of(null);
      })
    );
}

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let storageData = localStorage.getItem('csrf') ?? '';
    let storageUserGuid = localStorage.getItem('userGuid') ?? '';

    let request = req.clone({
      withCredentials: true,
      context: req.context
    });

    if (storageData) {
      request = req.clone({
        headers: req.headers
          .set('X-Csrf-Token', storageData)
          .set('userGuid', storageUserGuid)
          .set('ngrok-skip-browser-warning', "true"),
        withCredentials: true,
        context: req.context
      });
    }

    return next.handle(request).pipe(
      tap((event) => { }),

      catchError((err) => {
        let config = request.context.get(CONFIG);

        if (config?.isInitializer) {
          return throwError(() => err);
        }

        if (err.status == 401) {
          this.router.navigate(['/auth/login']);
          localStorage.clear();
        }

        return throwError(() => { return err.error });
      })
    );
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    BrowserAnimationsModule,
    ComponentsModule,
    HttpClientModule
  ],
  providers: [
    provideClientHydration(),
    { provide: NZ_I18N, useValue: en_US },
    provideAnimationsAsync(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, Router],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
