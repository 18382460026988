import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Credentials, PasswordChangeCredentials, PasswordResetCredentials } from '../models/credentials.model';
import { FormattedResponse } from '../models/formatted-response.model';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpContextConfig } from '../models/http-context-config.model';
import { User } from '../models/user.model';
import { CONFIG } from '../../app.module';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  currentUser = new BehaviorSubject<User | null>(null);

  login(credentials: Credentials) {
    return this.http.post<FormattedResponse<User>>(`${environment.api}/auth/login`, credentials);
  }

  logout() {
    return this.http.post<FormattedResponse<User>>(`${environment.api}/auth/logout`, {});
  }

  passwordRecovery(credentials: any) {
    return this.http.put<FormattedResponse<any>>(`${environment.api}/auth/recoveryPassword`, credentials);
  }

  changePassword(id: string | null, passwordCredentials: PasswordChangeCredentials) {
    return this.http.put<FormattedResponse<Credentials>>(`${environment.api}/auth/changePassword`, passwordCredentials);
  }

  resetPassword(id: string | null, passwordCredentials: PasswordResetCredentials) {
    return this.http.put<FormattedResponse<Credentials>>(`${environment.api}/Auth/RegenPassword?RecoveryPassword=${id}`, passwordCredentials);
  }

  me() {
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`);
  }

  getUser(isInitializer = false): Observable<FormattedResponse<User>> {
    let config = new HttpContextConfig();
    config.isInitializer = isInitializer;

    const context = new HttpContext().set(CONFIG, config);
    return this.http.get<FormattedResponse<User>>(`${environment.api}/auth/me`, { context: context });
  }
}
