import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsRoutingModule } from './components-routing.module';
import { NgZorroAntdModule } from './ng-zorro-antd/ng-zorro-antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from './base/base.component';
import { JsonFormsModule } from '@jsonforms/angular';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';

@NgModule({
  declarations: [
    BaseComponent,

  ],
  imports: [
    ComponentsRoutingModule,
    NgZorroAntdModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    AngularMaterialModule
  ],
  exports: [
    ComponentsRoutingModule,
    NgZorroAntdModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    AngularMaterialModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
